import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "./components/Pagination";
import Stepper from "./components/Stepper";
import Arrow from "./components/Arrow";
import searchTable, {
  findPayeed,
  numberOfDays,
  sortTable,
  totalPrize,
  sortedPrizes,
} from "./utils";
import useQuestsAPI from "../../../hooks/useQuestsAPI";
import Technologies from "./components/Technologies";
import { Filter } from "./components/Filter";
import { useAuth0 } from "@auth0/auth0-react";
import { users } from "../../../services/api/models";
import { useSelector } from "react-redux";
import { roleData } from "../../../validation/profileData.validation";
import { set } from "react-hook-form";
import useAccountProgressAPI from "../../../hooks/useGetAccountProgressAPI";
// import postDataUsingSDk from "../../../hooks/SDKUsageEx.ts";
const QuestLists = () => {
  // postDataUsingSDk()
  const [quests, setQuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [fullData, setFullData] = useState([]);
  const [filter, setFilter] = useState({ "$sort[id]": -1 });
  const [sortedBy, setSortedBy] = useState(""); // state for sorting column
  const [sortStatus, setSortStatus] = useState(""); // state for sorting status (ascending/descending/null)
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search"); // get search value from URL
  const { progress } = useAccountProgressAPI();

  useEffect(() => {
    if (searchValue && !isLoading) {
      const result = searchTable(searchValue, fullData);
      setQuests(result);
      setSortedBy("");
    } else {
      setQuests(fullData);
    }
  }, [searchValue, isLoading]);

  const handleSortTable = (column, status = "") => {
    let newSortStatus =
      status || sortedBy !== column || sortStatus === ""
        ? "asc"
        : sortStatus === "asc"
        ? "desc"
        : "";
    setSortStatus(newSortStatus);
    setSortedBy(column);
    if (newSortStatus) {
      setQuests(sortTable(quests, newSortStatus, column));
    } else {
      setSortStatus("");
      setSortedBy("");
      setQuests(fullData);
    }
  };

  return (
    <>
      <div className="d-flex flex-sm-row flex-column-reverse align-items-sm-center align-items-start justify-content-between mb-4 flex-wrap gap-3">
        <div className="d-flex align-items-center justify-content-center flex-wrap position-relative">
          <Filter
            filter={filter}
            setFilter={setFilter}
            setSortStatus={setSortStatus}
            setSortedBy={setSortedBy}
            setSkip={setSkip}
          />
        </div>
        <div>
          <div className="d-flex align-items-center gap-1 text-black">
            <span className="fs-20 fw-bold">{progress}%</span>
            {progress < 100 ? (
              <Link
                to="/app-profile"
                className="text-decoration-underline text-black fs-16"
              >
                Click here to complete Your Account
              </Link>
            ) : (
              <span className="text-black fs-16">
                You've Completed Your Account
              </span>
            )}
          </div>
          <div className="progress bg-light" style={{ height: "10px" }}>
            <div
              className={`progress-bar-${
                progress < 100 ? "warning" : "secondary"
              }`}
              role="progressbar"
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "5%" }}>
        <div className="col-xl-13">
          <div className="table-responsive">
            <table className="quest-table table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th>No</th>
                  <th>Quest</th>
                  <th>Type</th>
                  <th
                    className="tableHover"
                    onClick={() => handleSortTable("prize")}
                  >
                    Prize{" "}
                    {sortedBy === "prize" ? (
                      <Arrow direction={sortStatus} />
                    ) : (
                      <i className="fa-solid fa-sort"></i>
                    )}
                  </th>
                  <th className="tableHover progress-hover">Progress</th>
                  <th
                    className="tableHover days"
                    onClick={() => handleSortTable("days")}
                  >
                    Days{" "}
                    {sortedBy === "days" ? (
                      <Arrow direction={sortStatus} />
                    ) : (
                      <i className="fa-solid fa-sort"></i>
                    )}
                  </th>
                  <th>Status</th>
                  <th>Winners</th>
                  <th>Technologies</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {isLoading && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status"></div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                )}
                {quests.map((quest, index) => (
                  <tr key={quest.id}>
                    <td>
                      <Link
                        to={`/quest-view/${quest.id}`}
                        className="text-decoration-underline text-secondary"
                      >
                        #{quest.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/quest-view/${quest.id}`}
                        className="text-decoration-underline text-secondary"
                      >
                        {quest.title}
                      </Link>
                    </td>
                    <td className="wspace-no">
                      {quest.category.icon} {quest.category.name}
                    </td>
                    <td>🏆 {totalPrize(quest.prizes)} EGP</td>
                    <td>
                      <Stepper phase={quest.phase} />
                    </td>
                    <td>{numberOfDays(quest)}</td>
                    <td>
                      <span
                        className={`badge badge-lg light badge-${
                          quest.phase === "registration"
                            ? "success"
                            : quest.phase === "review"
                            ? "warning"
                            : quest.phase === "submission"
                            ? "danger"
                            : "dark"
                        }`}
                      >
                        {quest.phase}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex gap-1 justify-content-center">
                        {quest.prizes && findPayeed(quest.prizes) ? (
                          <>
                            {sortedPrizes(quest.prizes).map((prize, index) =>
                              prize.payee && prize.payee.id ? (
                                <div key={index} className="position-relative">
                                  <div className="winner-circle">
                                    <p className="m-0 fs-24">
                                      {+prize.rank === 1
                                        ? "🥇"
                                        : +prize.rank === 2
                                        ? "🥈"
                                        : "🥉"}
                                    </p>
                                  </div>
                                  <span className="bg-dark text-white winner-name">
                                    {prize.payee.nickname}
                                  </span>
                                </div>
                              ) : null
                            )}
                          </>
                        ) : (
                          <span
                            className={`badge badge-lg light badge-warning`}
                          >
                            unawarded
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <Technologies tech={quest.technologies} />
                    </td>
                    <td>
                      {/* view & edit & delete buttons */}
                      <div className="action-buttons d-flex justify-content-end">
                        <Link
                          to={`/quest-view/${quest.id}`}
                          className="btn btn-success light mr-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg-main-icon"
                            width="20px"
                            height="20px"
                            viewBox="0 0 32 32"
                            x="0px"
                            y="0px"
                          >
                            {" "}
                            <g data-name="Layer 21">
                              {" "}
                              <path
                                d="M29,14.47A15,15,0,0,0,3,14.47a3.07,3.07,0,0,0,0,3.06,15,15,0,0,0,26,0A3.07,3.07,0,0,0,29,14.47ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
                                fill="#000000"
                                fillRule="nonzero"
                              ></path>{" "}
                              <circle
                                cx="16"
                                cy="16"
                                r="3"
                                fill="#000000"
                                fillRule="nonzero"
                              ></circle>{" "}
                            </g>{" "}
                          </svg>
                        </Link>
                        {/* <Link to={"#"} className="btn btn-secondary light mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="20px" height="20px"></rect><path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path><rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect></g></svg>
                        </Link>
                        <Link to={"#"} className="btn btn-danger light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path><path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path></g></svg>
                        </Link> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Start Pagination */}
        <Pagination
          setQuests={setQuests}
          setIsLoading={setIsLoading}
          setSkip={setSkip}
          skip={skip}
          setFullData={setFullData}
          sortedBy={sortedBy}
          sortStatus={sortStatus}
          setSortStatus={setSortStatus}
          setSortedBy={setSortedBy}
          filter={filter}
        />
      </div>
    </>
  );
};
export default QuestLists;
