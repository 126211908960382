import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./index.css";
import useQuestsAPI from "../../../hooks/useQuestsAPI";
import { dateFormated } from "./utils";
import TaskSection from "./components/TaskSection";
import { useAuth0 } from "@auth0/auth0-react";
import searchTable, { findPayeed , sortedPrizes} from "./utils";
import {Button} from "react-bootstrap";
import {useRamadan} from "../../../context/RamadanContext";
const QuestView = () => {
  const { isRamadan } = useRamadan();

  const { id } = useParams(); // get quest id from QuestList page
  const navigate = useNavigate();
  const { isLoading, data, error } = useQuestsAPI(id);
  const { user } = useAuth0();
  const isAdmin = user.role === "admin";

  // Handle if user enter invalid id in url
  const res = (error) ? error.response: null;
  useEffect(() => {
    if (res && res.status === 404) {
      navigate("/page-error-404");
    }
  }, [res, navigate]);
  const winnersDivColor =["#ec10106b" , "#0aa0e791" , "rgb(76 175 80 / 56%)"] 
  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap justify-content-end">
      <Button className="me-2" variant="outline-primary btn-rounded" onClick={()=>navigate("/quest-list")}>
        <i className="fa-solid fa-chevron-left"></i> <span style={{margin:"0 10px"}}>Back</span>
       </Button>
      </div>
      {isLoading
      ? ( 
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
        )
      : (
        <div className="quest-view">
          <div className="overview">
            {
            ( data.prizes && findPayeed(data.prizes)) &&
                <div className="overview">
                <div>
                  <div className="card p-4">
                    <div className="card-header border-0 pb-3 px-0 pt-0 ">
                      <h4 className="fs-20">Winners</h4>
                    </div>
                    <div className="winners">
                    {
                      
                      sortedPrizes(data.prizes).map((prize, index) =>
                        prize.payee && prize.payee.id ? (
                        <div key={prize.payee.id} className="d-flex align-items-center justify-content-between gap-4 mb-3">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <div className="d-flex align-items-center justify-content-center py-3 px-3" 
                                style={{width:"0",height:"0",fontSize:"10px",color:"white",borderRadius:"50%" , backgroundColor:winnersDivColor[+prize.rank -1]}}>
                                  {prize.rank}
                            </div>
                            <p className="m-0">{prize.payee.nickname}</p>
                          </div>
                          <p className="fs-24 m-0">
                          {+prize.rank === 1
                              ? "🥇"
                              : +prize.rank === 2
                              ? "🥈"
                              : "🥉"}
                          </p>
                        </div>
                      ):null)
                    }
                    </div>
                  </div>
                </div>
            </div>
            }
           
            <div className="overview">
              <div>
                <div className="card p-4">
                  <div className="card-header border-0 pb-3 px-0 pt-0 ">
                    <h4 className="fs-20">Overview</h4>
                  </div>
                  <div>
                    <div>
                      <h6 className="m-0">🙋‍♀️ Register before:</h6>
                      <p className="px-4 fs-13">
                        {dateFormated(data.registrationDeadline)}
                      </p>
                    </div>
                    <div>
                      <h6 className="m-0">📦 Submit before:</h6>
                      <p className="px-4 fs-13">
                        {dateFormated(data.submissionDeadline)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h6
                      className="py-2 mx-4 font-w600"
                      style={{ color: "rgb(45, 138, 184)" }}
                    >
                      Registration{" "}
                      {data.phase === "registration" ? "Opened" : "Closed"}
                    </h6>
                    <div className="mx-2 mb-3 border-0">
                {
                  data.phase === "registration" ? (
                  <Link
                    className={`btn btn-primary px-5 mb-2 fs-14 ${isRamadan && "ramadan-button"}`}
                    to={`/quest-registration/${id}`}
                  >
                    {isRamadan && <div></div>}
                    <span className="fs-14" style={{fontWeight:"bold"}}>
                      REGISTER NOW{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-arrow-right ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                ) :
                data.phase === "submission" ? (
                  <Link
                    className={`btn btn-primary mb-2 fs-14 ${isRamadan && "ramadan-button"}`} style={{padding:"10px 48px"}}
                    to={`/quest-submission/${id}`}
                  >
                    {isRamadan && <div></div>}
                    <span className="fs-14" style={{fontWeight:"bold"}}>
                      SUBMIT{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-arrow-right ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                ) : null}
                <div></div>
              </div>
                    <div>
                      <h4>📈 Challenge Statistics</h4>
                      {isAdmin ? (
                        <>
                          <Link
                            to={`/quest-registrations/${id}`}
                          >
                            <h6 className="a-style"> 🙋‍♀️Registrations: {data.registrationsCount}</h6>
                          </Link>
                          <Link
                            className="a-style"
                            to={`/quest-submissions/${id}`}
                          >
                            <h6 className="m-0  a-style">
                              📦 Submissions: {data.submissionsCount}
                            </h6>
                          </Link>
                        </>
                      ) : (
                        <>
                           <p className="px-4 fs-14 m-0"> 🙋‍♀️Registrations: {data.registrationsCount}</p>
                          <p className="px-4 fs-14">
                            {" "}
                            📦 Submissions: {data.submissionsCount}
                          </p>
                        </>
                      )}
                      {/* <p>Updated every night </p> */}
                    </div>
                    <div>
                      <h4>🗒️ Challenge Details</h4>
                      <p className="px-4 fs-14 m-0">💪🏼 Difficulty: Intermediate</p>
                      <p className="px-4 fs-14">💯 Evaluation Criteria:</p>
                      <a
                        href={data.evaluationCriteriaLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="mx-4 text-decoration-underline"
                        style={{ color: "rgb(45, 138, 184)" }}
                      >
                        {" "}
                        Scorecard{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-start gap-5 flex-wrap">
                <h3>{data.title}</h3>
                <div className="places d-flex align-items-center gap-5 text-center">
                  {/*Prizes Container*/}
                  {data.prizes && sortedPrizes(data.prizes).map((prize, index) => (
                    <div key={index}>
                      <h3 className="m-0">
                        {+prize.rank === 1
                          ? "🥇"
                          : +prize.rank === 2
                          ? "🥈"
                          : "🥉"}
                        {prize.value}{" "}
                        <sub className="fs-15">{prize.currency}</sub>
                      </h3>
                      <p className="m-0">{prize.rank}st Place</p>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="card-body pb-0"  dangerouslySetInnerHTML={{__html: data.description}}> */}
              <div className="card-body pb-0">
                {/*Description Container*/}
                <h4 className="fs-20 mb-3">Description</h4>
                <div
                  className="ms-4"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                >
                  {/* {data.description.split(".").map((sentence, index) => (
                        <p key={index} className="mb-1">
                          {sentence}.
                        </p>
                      ))} */}
                </div>
                <hr />
                <div className="tasks">
                  <TaskSection data={data.tasks} header={"Tasks"} />
                  <TaskSection
                    data={data.acceptanceCriteria}
                    header={"Acceptance Criteria"}
                  />
                  <TaskSection
                    data={data.requirements}
                    header={"Requirements"}
                  />
                </div>
              </div>
              <div className="card-footer border-0">
                {
                  data.phase === "registration" ? (
                  <Link
                    className={`btn btn-primary px-5 mb-4 fs-14 ${isRamadan && "ramadan-button"}`}
                    to={`/quest-registration/${id}`}
                  >
                    {isRamadan && <div></div>}
                    <span className="fs-14" style={{fontWeight:"bold"}}>
                      REGISTER NOW{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-arrow-right ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                ) :
                data.phase === "submission" ? (
                  <Link
                    className={`btn btn-primary mb-2 fs-14 ${isRamadan && "ramadan-button"}`}
                    style={{padding:"10px 48px"}}
                    to={`/quest-submission/${id}`}
                  >
                    {isRamadan && <div></div>}
                    <span className="fs-14" style={{fontWeight:"bold"}}>
                      SUBMIT{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-arrow-right ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </span>
                  </Link>
                ) : null}
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default QuestView;
