export const ROLES = ["client", "reviewer", "contestant"];

export const POSITIONS = ["Designer", "Developer"];

export const STUDY_YEARS = ["1", "2", "3", "4", "Graduate"];

export const JOB_STATES = [
  "Part time",
  "Full time",
  "Free hours",
  "Intern",
  "Other",
];

export const FIELDS_INTEREST = [
  "Cyber Security",
  "Game Development",
  "Game Art",
  "Game Design",
  "Devops / Cloud",
  "Backend",
  "Frontend",
  "QA (Testing)",
  "UI / UX",
  "Mobile Dev (Cross Platform)",
  "Android",
  "IOS",
  "Machine Learning",
  "Model Data",
  "Robotics",
  "IOT",
  "Other",
];

export const AWARENESS_CHANNELS = [
  "From Our WhatsApp Community",
  "From Our Website",
  "From Linkedin",
  "From Instagram",
  "From Facebook",
  "From University",
  "From Friends",
  "Other",
];
