import React, { createContext, useState, useContext } from "react";
import '../css/ramadan-theme.css';
const RamadanContext = createContext();

export const RamadanProvider = ({ children }) => {
  const [isRamadan, setIsRamadan] = useState(true);

  return (
    <RamadanContext.Provider value={{ isRamadan, setIsRamadan}}>
      {children}
    </RamadanContext.Provider>
  );
};

export const useRamadan = () => useContext(RamadanContext);
