import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { roleData } from "../validation/profileData.validation";

const useAccountProgressAPI = () => {
  const { auth } = useSelector((state) => state.auth);
  const [data, setData] = useState({ progress: 0, missingFields: [] });

  useEffect(() => {
    // check if user object has fields
    if (auth && Object.keys(auth.user).length > 0) {
      // calculate role fields count
      const userRoleFields = roleData(auth.user);
      const userRoleFieldsCount = Object.keys(userRoleFields).length;

      // Identify empty fields
      const missingFields = Object.entries(userRoleFields)
        .filter(([_, value]) => !value)
        .map(([key]) => key);

      // calculate and set progress
      const progress = Math.round(
        ((userRoleFieldsCount - missingFields.length) / userRoleFieldsCount) *
          100
      );
      setData({ progress, missingFields });
    }
  }, [auth]);

  return data;
};

export default useAccountProgressAPI;
