import * as yup from "yup";
export const getValidationSchema = (isRequired, submissionContext = false) => {
  return yup.object().shape({
    name: yup
      .string()
      .required("Name is required")
      .trim()
      .min(3, "Name must be at least 3 characters")
      .max(50, "Name must be at most 50 characters"),
    whatsAppPhoneNumber: yup
      .string()
      .required("Whatsapp Phone Number is required")
      .matches(
        /^\+?[1-9]\d{1,14}$/,
        "Invalid WhatsApp phone number. It should start with a '+' followed by your country code and number, e.g., +201034567890."
      ),
    role: yup.string().required("Role is required"),
    nickname: yup.string().when("role", {
      is: (value) => value !== "client",
      then: yup
        .string()
        .required("Nickname is required")
        .trim()
        .min(3, "Nickname must be at least 3 characters")
        .max(50, "Nickname must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),

    position: yup.string().when("role", {
      is: (role) => role !== "client",
      then: submissionContext
        ? yup.string().optional().nullable()
        : yup.string().required("Position is required"),
      otherwise: yup.string().optional().nullable(),
    }),

    githubHandle: yup.string().when(["role", "position"], {
      is: (role, position) => role !== "client" && position === "Developer",
      then: yup
        .string()
        .required("GitHub Handle is required")
        .trim()
        .min(3, "GitHub Handle must be at least 3 characters")
        .max(50, "GitHub Handle must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),

    behanceProfileLink: yup.string().when(["role", "position"], {
      is: (role, position) => role !== "client" && position === "Designer",
      then: yup
        .string()
        .required("Behance link is required")
        .trim()
        .min(3, "Behance link must be at least 3 characters")
        .max(50, "Behance link must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
    terms: isRequired
      ? yup
          .boolean()
          .oneOf([true], "You must agree to the terms of service")
          .required("You must agree to the terms of service")
      : yup.boolean(),

    interestFields: submissionContext
      ? yup
          .array()
          .required("Interest fields are required")
          .min(1, "At least one interest field is required")
      : yup.array().nullable(),
    linkedinProfileLink: yup.string().when("role", {
      is: (role) => role !== "client",
      then: yup
        .string()
        .trim()
        .nullable()
        .test(
          "linkedin-profile-link",
          "Invalid LinkedIn profile URL. It should be in the format https://www.linkedin.com/in/username.",
          (value) => {
            if (!value) return true; // Skip validation if empty
            return /^(https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?)$/.test(
              value
            );
          }
        )
        .when("$submissionContext", {
          is: (submissionContext) => submissionContext,
          then: yup.string().required("LinkedIn profile link is required"),
        }),
      otherwise: yup.string().nullable(),
    }),
    studyYear: yup.string().when("role", {
      is: (role) => role === "contestant",
      then: submissionContext
        ? yup.string().required("Study year is required")
        : yup.string().nullable(),
    }),
    university: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) =>
        role === "contestant" && studyYear && studyYear !== "Graduate",
      then: yup
        .string()
        .nullable()
        .trim()
        .test(
          "university",
          "University name must be at least 3 characters",
          (value) => !value || value.trim().length >= 3
        )
        .max(50, "University name must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),

    faculty: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) =>
        role === "contestant" && studyYear && studyYear !== "Graduate",
      then: yup
        .string()
        .nullable()
        .trim()
        .test(
          "faculty",
          "Faculty name must be at least 3 characters",
          (value) => !value || value.trim().length >= 3
        )
        .max(50, "Faculty name must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),

    companyName: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) =>
        role === "client" || role === "reviewer" || studyYear === "Graduate",
      then: yup
        .string()
        .nullable()
        .trim()
        .test(
          "companyName",
          "Company name must be at least 3 characters",
          (value) => !value || value.trim().length >= 3
        )
        .max(50, "Company name must be at most 50 characters"),
      otherwise: yup.string().optional().nullable(),
    }),
    experienceYears: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => role !== "reviewer" || studyYear === "Graduate",
      then: yup
        .string()
        .nullable()
        .optional() // Make it optional
        .matches(/^\d*$/, "Experience Years must be a number") // Allow empty string
        .test(
          "is-valid-range",
          "Experience Years must be between 0 and 30",
          (value) => {
            if (!value) return true; // Allow empty value
            const num = parseInt(value, 10);
            return num >= 0 && num <= 30;
          }
        ),
      otherwise: yup.string().nullable().optional(), // Make it optional
    }),

    jobState: yup.string().when(["role", "studyYear"], {
      is: (role, studyYear) => role !== "reviewer" || studyYear === "Graduate",
      then: yup.string().nullable(),
      otherwise: yup.string().optional().nullable(),
    }),

    where_you_know_us: yup
      .array()
      .nullable()
      .when(["$whereYouKnowUsHasValues", "$submissionContext"], {
        is: (hasValues, submissionContext) => hasValues || submissionContext,
        then: yup
          .array()
          .min(1, "At least one option must be selected")
          .of(yup.string().required("Each option must be a valid string")),
        otherwise: yup.array().nullable().optional(),
      }),
    otherAwarenessChannel: yup.string().when("where_you_know_us", {
      is: (whereYouKnowUs) =>
        whereYouKnowUs && whereYouKnowUs.includes("Other"),
      then: yup
        .string()
        .required("Other field is required")
        .min(3, "Other field must be at least 3 characters")
        .max(50, "Other field must be at most 50 characters")
        .matches(
          /^[a-zA-Z0-9\s]+$/,
          "Other field must contain only letters, numbers, and spaces"
        ), // Only allow letters, numbers, and spaces
      otherwise: yup.string().nullable().optional(),
    }),

    country: yup
      .string()
      .nullable()
      .transform((value) => (value === null ? "" : value))
      .test(
        "country",
        "Country must be at least 3 characters",
        (value) => !value || value.trim().length >= 3
      )
      .when("$submissionContext", {
        is: (submissionContext) => submissionContext,
        then: yup.string().required("Country is required"),
        otherwise: yup.string().nullable(),
      })
      .max(50, "Country must be at most 50 characters"),

    city: yup
      .string()
      .nullable()
      .transform((value) => (value === null ? "" : value))
      .test(
        "city",
        "City must be at least 3 characters",
        (value) => !value || value.trim().length >= 3
      )
      .when("$submissionContext", {
        is: (submissionContext) => submissionContext,
        then: yup.string().required("City is required"),
        otherwise: yup.string().nullable(),
      })
      .max(50, "City must be at most 50 characters"),
  });
};

export const handleErrorMessage = (errorMessage) => {
  const match = errorMessage.match(/unique constraint "([^"]+)"/);

  if (match && match[1]) {
    const uniqueConstraint = match[1];
    switch (uniqueConstraint) {
      case "users_phonenumber_unique":
        return "WhatsApp phone number must be unique";
      case "users_handle_unique":
        return "GitHub Handle and Nickname must be unique";
      case "users_behance_unique":
        return "Behance link must be unique";
      case "users_linkedinprofile_unique":
        return "LinkedIn profile must be unique";
      case "users_other_awareness_channel_unique":
        return "Other awareness channel must be unique";
      default:
        return "This field must be unique.";
    }
  } else {
    console.log("Unique constraint not found.");
  }
};

export const roleData = (data) => {
  const {
    name,
    role,
    nickname,
    whatsAppPhoneNumber,
    university,
    faculty,
    studyYear,
    interestFields,
    companyName,
    jobState,
    experienceYears,
    githubHandle,
    behanceProfileLink,
    position,
    linkedinProfileLink,
    where_you_know_us,
    country,
    city,
  } = data;

  // Join interestFields and awarenessChannels into strings
  const interest =
    interestFields && typeof interestFields !== "string"
      ? interestFields.join(", ")
      : interestFields || "";

  // Safely handle where_you_know_us (if undefined or not an array, default to empty array)
  const whereYouKnowUs =
    where_you_know_us && typeof where_you_know_us !== "string"
      ? where_you_know_us.join(", ")
      : where_you_know_us || "";

  // Base data for all roles
  let newData = {
    name,
    role,
    whatsAppPhoneNumber,
    country,
    city,
    interestFields: interest,
    where_you_know_us: whereYouKnowUs,
  };

  // Add role-specific data
  if (role === "client") {
    // Client-specific fields
    newData = { ...newData, companyName };
  } else {
    // Non-client roles (reviewer, contestant)
    newData = {
      ...newData,
      nickname,
      linkedinProfileLink,
    };

    // Add position-specific fields
    if (position === "Designer") {
      newData = { ...newData, behanceProfileLink };
    } else if (position === "Developer") {
      newData = { ...newData, githubHandle };
    }

    if (studyYear === "Graduate") {
      newData = {
        ...newData,
        studyYear,
      };
    }

    if (studyYear === "Graduate" || role === "reviewer") {
      newData = {
        ...newData,
        companyName,
        jobState,
        experienceYears: +experienceYears,
      };
    } else if (studyYear !== "Graduate" && studyYear !== "") {
      newData = { ...newData, studyYear, university, faculty };
    } else if (role === "contestant" && studyYear === "") {
      newData = {
        ...newData,
        studyYear,
        university,
        faculty,
        companyName,
        jobState,
        experienceYears: 0,
      };
    }
  }

  console.log(newData);
  return newData;
};
