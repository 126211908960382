import { lazy, Suspense, useEffect } from "react";
// Components
import Index from "./jsx/index";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// Actions
import { connect, useDispatch, useSelector } from "react-redux";
// Style
import "../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import ProfileData from "./jsx/pages/ProfileData";
import { loginConfirmedAction } from "./store/actions/AuthActions";
import { auth, users } from "./services/api/models";

function App() {
  const { auth } = useSelector((state) => state.auth);
  const { isAuthenticated, isLoading, loginWithRedirect, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get("state");
  
    if ( !isAuthenticated && !isLoading) {
      if(state){
        loginWithRedirect({appState: {returnTo: `/quest-view/${state}`}});
      }
      else{
        loginWithRedirect({appState: {returnTo: "/"}});
      }
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    // if user is auth0 authenticated, but not saved in our state
    const storeUserData = async () => {
      if (isAuthenticated && Object.keys(auth.user).length === 0) {
        const accessToken = await getAccessTokenSilently();
        
        // get database user
        const headers = { Authorization: `Bearer ${accessToken}` }
        const options = { headers: headers }
        const dbUser = await users.usersGet(1, 0, {auth0Id: user.sub}, options);
        // merge the two users to get all data
        if (dbUser.data.data.length !== 0) {
          const dbUserData = dbUser.data.data[0];

          // Dynamically merge user and dbUserData, prioritizing dbUserData values
          const mergedUser = {
            ...user,
            ...Object.keys(dbUserData).reduce((acc, key) => {
              // ensures the key belongs directly to dbUserData (not inherited from the prototype chain)
              if (dbUserData.hasOwnProperty(key)) { 
                acc[key] = dbUserData[key] ?? user[key] ?? "";
              }
              return acc;
            }, {}),
          };
          dispatch(loginConfirmedAction({ user: mergedUser, accessToken }));
        }
        // else{
        //   dispatch(loginConfirmedAction({ user, accessToken }));
        // }      
      }
    };
    storeUserData();
  }, [isAuthenticated, auth.user, user, getAccessTokenSilently, getIdTokenClaims, dispatch]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  // user is authenticated by auth0, but hasn't chosen a role yet
  if (isAuthenticated && user['role'] === undefined) {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <ProfileData />
      </Suspense>
    );
  }
  else if (isAuthenticated) {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Index />
      </Suspense>
    );
  }

  return null;
}

export default App;
